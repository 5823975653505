import React, { CSSProperties } from 'react';
import { useTranslation } from 'next-i18next';
import theme, { fonts } from '@/theme';

const Terms = () => {
  const { t } = useTranslation(['accountAccess']);
  return (
    <p style={styles.terms}>
      <p style={styles.terms}>
        {t('byContinue')}&nbsp;
        <a style={styles.link} href="https://thndr.app/terms/">
          {t('terms')}
        </a>
        &nbsp;{t('and')}&nbsp;
        <a style={styles.link} href="https://thndr.app/privacyPolicy/">
          {t('privacy')}
        </a>
      </p>
    </p>
  );
};

export default Terms;

let styles: Record<string, CSSProperties> = {
  terms: {
    ...fonts.smallRegular,
    textAlign: 'center',
    color: 'var(--primary-color)',
    marginTop: 10,
  },

  link: {
    ...fonts.smallRegular,
    color: 'var(--color-blue)',
  },
};
