import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { Button as ButtonANT } from 'antd';
import { Section, Flex, Box } from './styles';
import { useTranslation } from 'next-i18next';
import VerifyEmail from '../VerifyEmail/VerifyEmail'; /* fix: it's wrong to import a page into another */

import { useActions } from '@/store';
import ContinueWithEmailForm from '@/components/account-access/forms/ContinueWithEmail/ContinueWithEmail';
import Terms from '@/components/account-access/Terms/Terms';
import AccountAccessLayout from '@/layouts/AccessAccountLayout';
import { useStates } from '@/store';
import { IAccessAccountQueryParams } from '@/constants/routes';
import theme from '::theme';
import { Button } from '@/components/atoms/Button/Button';

export const AccountAccess: NextPage<IAccessAccountQueryParams> =
  function AccountAccess(query) {
    const { t } = useTranslation(['accountAccess']);
    const [stepIndex, setStepIndex] = useState(0);
    const { accountAccess } = useActions();
    const {
      accountAccess: {
        isLoadingRequestEmailVerification: loading,
        isLoadingGoogle,
        isLoadingApple,
      },
    } = useStates();

    useEffect(() => {
      accountAccess.capturePreLoginState();
    }, []);

    const steps = [
      <>
        <ContinueWithEmailForm loading={loading} nextStep={nextStep} />
        <Section>{t('or')}</Section>
        <Box>
          {!query.noSocial && (
            <Flex>
              <Button
                disabled={loading}
                loading={isLoadingApple}
                icon={'/social/appleLogo.svg'}
                backgroundColor={'#000'}
                onPress={accountAccess.continueWithApple}>
                {t('continueWithApple')}
              </Button>
              <Button
                disabled={loading}
                loading={isLoadingGoogle}
                icon={'/social/googleLogo.svg'}
                backgroundColor={isLoadingGoogle ? '#000' : '#FFF'}
                textColor={'#000'}
                borderColor={'var(--primary-color)'}
                onPress={accountAccess.continueWithGoogle}>
                {t('continueWithGoogle')}
              </Button>
            </Flex>
          )}
        </Box>
        <Terms />
      </>,
      <>
        <VerifyEmail />
        <br />
        <ButtonANT onClick={previousStep}>{t('back')}</ButtonANT>
      </>,
    ];

    const totalSteps = steps.length;
    const lastStepIndex = totalSteps - 1;

    function nextStep() {
      if (stepIndex < lastStepIndex) {
        setStepIndex((prevStep) => prevStep + 1);
      }
    }

    function previousStep() {
      if (stepIndex > 0) {
        setStepIndex((prevStep) => prevStep - 1);
      }
    }

    // if (typeof window !== "undefined") {
    //   console.log('router', router.query.social);
    //       //@ts-ignore
    //   actions.accountAccess.setSocialAttribute(router.query.social);
    // }

    return <AccountAccessLayout>{steps[stepIndex]}</AccountAccessLayout>;
  };
