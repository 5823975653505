import { i18n } from 'next-i18next';
export const validateEmail = (mail: string) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(mail).toLowerCase())) {
    return i18n?.t('validation.email');
  } else {
    return null;
  }
};

export const validateUsername = (username: string) => {
  console.log('validator', username);
  const usernameValidator = /^(?=.{9,20}$)[a-z][a-z_0-9]*$/;
  if (!usernameValidator.test(String(username).toLowerCase())) {
    console.log('here');
    return i18n?.t('validation.validateUsername');
  } else {
    return null;
  }
};
