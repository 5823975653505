import { useTranslation } from 'next-i18next';
import { useState, useEffect, useCallback, useMemo } from 'react';

import * as Styles from './styles';

import theme, { fonts } from '@/theme';
import { Button } from '@/components/atoms/Button/Button';
import VerifyEmailForm from '@/components/account-access/forms/VerifyEmailForm/VerifyEmailForm';
import { useActions, useStates, useEffects } from '@/store';

export default function VerifyEmail() {
  const { t } = useTranslation(['accountAccess']);
  const { accountAccess } = useStates();
  const { canRequestEmailCode, reRequestEmailCodeText, resendEmail } =
    useResendCodeCounter();

  const state = useStates();
  const loading = state.accountAccess.isLoadingVerifyEmail;

  return (
    <>
      {/* <Styles.Image src={'/icons/sent.svg'} /> */}
      <>
        <Styles.Title>{t('enterCode')}</Styles.Title>
        <Styles.Subtitle>{t('codeDescription')}</Styles.Subtitle>
        <Styles.Email>{accountAccess.email}</Styles.Email>
      </>
      <VerifyEmailForm email={accountAccess.email} loading={loading} />
      <Styles.Question>{t('notRecieveDescription')}</Styles.Question>
      <Button onPress={resendEmail} disabled={!canRequestEmailCode || loading}>
        {reRequestEmailCodeText}
      </Button>
      <Styles.MiddleText>{t('or')}</Styles.MiddleText>
    </>
  );
}

function useResendCodeCounter() {
  const { accountAccess } = useActions();
  const { t } = useTranslation(['accountAccess']);
  const [counter, setCounter] = useState(20);
  useEffect(() => {
    const timer = setTimeout(
      () => (counter > 0 ? setCounter(counter - 1) : null),
      1000,
    );
    return () => clearTimeout(timer);
  }, [counter]);
  const resendEmail = useCallback(() => {
    if (counter === 0) {
      accountAccess.resendEmail();
      setCounter(20);
    }
  }, [counter]);
  const canRequestEmailCode = useMemo(() => counter === 0, [counter]);
  const reRequestEmailCodeText = useMemo(
    () =>
      t('resendCode', {
        in: counter > 0 ? `in ${counter} sec...` : '',
      }),
    [counter],
  );
  return { resendEmail, reRequestEmailCodeText, canRequestEmailCode };
}
