import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  color: var(--primary-color);
`;
export const Box = styled.div``;
