import styled from 'styled-components';

import theme from '@/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-weight: normal;
  font-size: ${theme.fontSizes[4]}px;
  margin: 0 0 ${theme.space[2]}px 0;
  color: var(--color-text-dark);
`;

export const Subtitle = styled.p`
  font-size: ${theme.fontSizes[3]};
  margin: 0 0 ${theme.space[3]}px 0;
  color: var(--color-text-dark);
`;
