import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { Container, Title, Subtitle } from './styles';
import { validateEmail } from '@/util/validation';
import { useActions } from '@/store';
import { Button } from '@/components/atoms/Button/Button';
import { TextInput } from '@/components/atoms/TextInput/TextInput';

type FormData = {
  email: string;
  nextStep: () => void;
};

function ContinueWithEmailForm(props) {
  const { accountAccess } = useActions();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<FormData>();
  const { t } = useTranslation(['accountAccess', 'common']);

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      await accountAccess.continueWithEmail(email.toLowerCase());
      props.nextStep();
    },
    [accountAccess],
  );

  useEffect(() => {
    setFocus('email');
  }, []);
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Title>{t('title', { ns: 'accountAccess' })}</Title>
          <Subtitle>{t('subTitle', { ns: 'accountAccess' })}</Subtitle>
          <TextInput
            name="email"
            placeholder={t('emailAddress', { ns: 'accountAccess' })}
            {...register('email', { required: true, validate: validateEmail })}
          />
          {errors.email?.type == 'required' && (
            <span style={{ color: 'var(--color-red)' }}>
              {t('validation.emailRequired', { ns: 'common' })}
            </span>
          )}
          {errors.email?.type == 'validate' && (
            <span style={{ color: 'var(--color-red)' }}>
              {errors.email.message}
            </span>
          )}
          <Button loading={props.loading} behavior="submit">
            {t('continueWithEmail', { ns: 'accountAccess' })}
          </Button>
        </Container>
      </form>
    </Container>
  );
}

export default ContinueWithEmailForm;
