import styled from 'styled-components';

import theme from '@/theme';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 40%;
  max-width: 100px;
  margin-bottom: ${theme.space[5]}px;
  align-self: center;
`;

export const Title = styled.p`
  ${({ theme }) => (theme.rtl ? `text-align:  right;` : `text-align:  left;`)};
  font-weight: ${theme.fontWeights.semiBold};
  color: var(--color-text-dark);
`;

export const Subtitle = styled.p`
  margin-top: 0;
  ${({ theme }) => (theme.rtl ? `text-align:  right;` : `text-align:  left;`)};
  margin-bottom: ${theme.space[1]}px;
  color: var(--color-text-dark);
`;

export const Question = styled.p`
  font-size: ${theme.fontSizes[0]}px;
  margin: ${theme.space[2]}px;
  color: var(--color-text-dark);
`;

export const MiddleText = styled.p`
  color: var(--color-text-dark);
  font-size: ${theme.fontSizes[0]}px;
  margin-left auto;
  margin-right: auto;
  margin-top: 10px;
`;

export const Email = styled.p`
  ${({ theme }) => (theme.rtl ? `text-align:  right;` : `text-align:  left;`)};
  margin: 0 0 ${theme.space[4]}px 0;
  color: var(--color-text-dark);
`;
