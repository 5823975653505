import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';

import * as Styles from './styles';

import { useActions } from '@/store';
import { Button } from '@/components/atoms/Button/Button';
import { TextInput } from '@/components/atoms/TextInput/TextInput';

type FormData = {
  code: string;
};

function ContinueWithEmailForm(props) {
  const { t } = useTranslation(['accountAccess', 'common']);
  const { accountAccess } = useActions();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<FormData>();
  useEffect(() => {
    setFocus('code');
  }, []);
  const onSubmit = useCallback(
    async ({ code }) => {
      await accountAccess.verifyEmail(code);
    },
    [accountAccess],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Styles.Container>
        <TextInput
          placeholder={t('sixDigits')}
          name="code"
          {...register('code', { required: true })}
        />
        {errors.code?.type == 'required' && (
          <span>{t('validation.codeRequired')}</span>
        )}
        <Button
          loading={props.loading}
          disabled={props.loading}
          behavior="submit">
          {t('verifyCode')}
        </Button>
      </Styles.Container>
    </form>
  );
}

export default ContinueWithEmailForm;
