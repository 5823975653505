import styled from 'styled-components';

import theme from '@/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.p`
  text-align: left;
  font-size: ${theme.fontSizes[3]};
  margin: 0 0 ${theme.space[3]}px 0;
`;
